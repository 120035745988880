const yttc200 = {
  rates: {
    private: "EUR 1600",
    shared: "EUR 1200",
  },
  dates: ["3 January to 26 January 2024"],
};

const yttc100hathaAshVin = {
  rates: {
    private: "EUR 900",
    shared: "EUR 650",
  },
  dates: [
    "28 November to 9 December 2024",
    "22 December to 2 January 2025",
    "15 January to 26 January 2025",
  ],
};

const yttc100AerialYin = {
  rates: {
    private: "EUR 1100",
    shared: "EUR 800",
  },
  dates: [
    "16 November to 27 November 2024",
    "10 December to 21 December 2024",
    "3 January to 14 January 2025",
  ],
};

const yttc50Hatha = {
  rates: {
    private: "EUR 800",
    shared: "EUR 600",
    triple: "EUR 500",
  },
  dates: [
    "23 March to 30 March 2025",
    "13 April to 20 April 2025",
    "04 May to 11 May 2025",
    "25 May to 01 June 2025",
    "15 June to 22 June 2025",
    "6 July to 13 July 2025",
    "27 July to 3 August 2025",
    "17 August to 24 August 2025",
    "7 September to 14 September 2025",
    "28 September to 05 October 2025",
    "19 October to 26 October 2025",
    "9 November to 16 November 2025",
    "30 November to 07 December 2025",
    "21 December to 28 December 2025",
  ],
};

const yttc50AerialAcro = {
  rates: {
    private: "EUR 800",
    shared: "EUR 600",
    triple: "EUR 500",
  },
  dates: [
    "16 March to 23 March 2025",
    "06 April to 13 April 2025",
    "27 April to 04 May 2025",
    "18 May to 25 May 2025",
    "08 June to 15 June 2025",
    "29 June to 06 July 2025",
    "20 July to 27 July 2025",
    "10 August to 17 August 2025",
    "31 August to 07 September 2025",
    "21 September to 28 September 2025",
    "12 October to 19 October 2025",
    "2 November to 09 November 2025",
    "23 November to 30 November 2025",
    "14 December to 21 December 2025",
    "4 January to 11 January 2026",
  ],
};

const yttc50Ashtanga = {
  rates: {
    private: "EUR 800",
    shared: "EUR 600",
    triple: "EUR 500",
  },
  dates: [
    "30 March to 06 April 2025",
    "20 April to 27 April 2025",
    "11 May to 18 May 2025",
    "01 June to 08 June 2025",
    "22 June to 29 June 2025",
    "13 July to 20 July 2025",
    "3 August to 10 August 2025",
    "24 August to 31 August 2025",
    "14 September to 21 September 2025",
    "5 October to 12 October 2025",
    "26 October to 02 November 2025",
    "16 November to 23 November 2025",
    "7 December to 14 December 2025",
    "28 December to 04 January 2026",
  ],
};

const yttc15days100HathaAshtanga = {
  rates: {
    private: "EUR 1200",
    shared: "EUR 900",
    triple: "EUR 800",
  },
  dates: [
    "23 March to 06 April 2025",
    "13 April to 27 April 2025",
    "04 May to 18 May 2025",
    "25 May to 08 June 2025",
    "15 June to 29 June 2025",
    "6 July to 20 July 2025",
    "27 July to 10 August 2025",
    "17 August to 31 August 2025",
    "7 September to 21 September 2025",
    "28 September to 12 October 2025",
    "19 October to 2 November 2025",
    "9 November to 23 November 2025",
    "30 November to 14 December 2025",
    "21 December to 04 January 2026",
  ],
};

const yttc15days100AshtangaAerial = {
  rates: {
    private: "EUR 1200",
    shared: "EUR 900",
    triple: "EUR 800",
  },
  dates: [
    "30 March to 13 April 2025",
    "20 April to 04 May 2025",
    "11 May to 25 May 2025",
    "01 June to 15 June 2025",
    "22 June to 06 July 2025",
    "13 July to 27 July 2025",
    "3 August to 17 August 2025",
    "24 August to 07 September 2025",
    "14 September to 28 September 2025",
    "5 October to 19 October 2025",
    "26 October to 09 November 2025",
    "16 November to 30 November 2025",
    "7 December to 21 December 2025",
    "28 December to 11 January 2026",
  ],
};

const yttc22days200HathaAshtangaAerialAcro = {
  rates: {
    private: "EUR 1600",
    shared: "EUR 1200",
    triple: "EUR 1000",
  },
  dates: [
    "16 March to 6 April 2025",
    "23 March to 13 April 2025",
    "30 March to 20 April 2025",
    "6 April to 27 April 2025",
    "13 April to 4 May 2025",
    "20 April to 11 May  2025",
    "27 April to 18 May 2025",
    "4 May 25 to 25 May 2025",
    "11 May to 1 June 2025",
    "18 May to 8 June 2025",
    "25 May to 15 June 2025",
    "1 June to 22 June 2025",
    "8 June to 29 June 2025",
    "15 June to 6 July 2025",
    "22 June to 13 July 2025",
    "29 June to 20 July 2025",
    "6 July to 27 July 2025",
    "13 July to 3 August 2025",
    "20 July to 10 August 2025",
    "27 July to 17 August 2025",
    "3 August to 24 August 2025",
    "10 August to 31 August 2025",
    "17 August to 7 September 2025",
    "24 August to 14 September 2025",
    "31 August to 21 September 2025",
    "7 September to 28 September 2025",
    "14 September to 5 October 2025",
    "21 September to 12 October 2025",
    "28 September to 19 October 2025",
    "5 October to 26 October 2025",
    "12 October to 2 November 2025",
    "19 October to 9 November 2025",
    "26 October to 16 November 2025",
    "2 November to 23 November 2025",
    "9 November to 30 November 2025",
    "16 November to 7 December 2025",
    "23 November to 14 December 2025",
    "30 November to 21 December 2025",
    "7 December to 28 December 2025",
    "14 December to 4 January 2026",
    "21 December to 11 January 2026",
    "28 December to 18 January 2026",
    "4 January to 25 January 2026",
  ],
};

const yogaHolidaysBaliDates = {
  rates: {
    private: "EUR 700",
    shared: "EUR 500",
  },
  dates: ["Custom Dates, enter your dates in Special request section"],
};

const yttc200Bali200Puri = {
  rates: {
    private: "EUR 1500",
    shared: "EUR 1100",
  },
  dates: [
    "29 September 20 October 2024",
    "20 October 2024 10 November 2024",
    "10 November 1 December 2024",
    "1 December 22 December 2024",
  ],
};

export {
  yttc200,
  yttc100hathaAshVin,
  yttc100AerialYin,
  yttc50Hatha,
  yttc50AerialAcro,
  yttc50Ashtanga,
  yttc15days100HathaAshtanga,
  yttc15days100AshtangaAerial,
  yttc22days200HathaAshtangaAerialAcro,
  yogaHolidaysBaliDates,
  yttc200Bali200Puri,
};
